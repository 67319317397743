export const state = () => ({
  loading: false,
  message: null,
  pageTitle: '',
  backLink: null,
  pageAction: null,
  breadcrumbs: [],
  privacyPolicyURL: 'https://www.iubenda.com/privacy-policy/16070715',
  cookiePolicyURL: 'https://www.iubenda.com/privacy-policy/16070715/cookie-policy',
  termsAndConditionsURL: 'https://www.iubenda.com/termini-e-condizioni/16070715',
})

export const mutations = {
  SET_LOADING(state, status) {
    state.loading = status;
  },
  SET_PAGE_TITLE(state, payload) {
    state.pageTitle = payload;
  },
  SET_PAGE_ACTION(state, payload) {
    state.pageAction = payload;
  },
  SET_BACKLINK(state, payload) {
    state.backLink = payload;
  },
  SET_BREADCRUMBS(state, payload) {
    state.breadcrumbs = payload;
  }
}

export const actions = {}
