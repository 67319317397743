export const state = () => ({
  sessions: []
});

export const mutations = {
  SET_SESSIONS(state, payload) {
    state.sessions = [...payload];
  },
};

export const actions = {
  async getSession({ commit }, id) {
    commit('SET_LOADING', true, { root: true });

    try {
      /** @type {TrainingCourseModel[]} */
      const res = await this.$axios.get(`/trainingSessions/${id}`);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async getSessions({ commit }, params = {}) {
    commit('SET_LOADING', true, { root: true });

    try {
      /** @type {TrainingCourseModel[]} */
      const res = await this.$axios.get(`/trainingSessions`, { params });
      commit('SET_SESSIONS', res.data);
      return res.data || [];
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async createSession({ commit }, session) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.post(`/trainingSessions`, session);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;

    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async updateSession({ commit }, session) {
    commit('SET_LOADING', true, { root: true });

    try {
      await this.$axios.patch(`/trainingSessions/${session.id}`, {
        ...session,
        id: undefined
      });

      return true;

    } catch (err) {
      console.error(err);

      return false;

    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async addParticipantToSession({ commit }, payload) {
    try {
      await this.$axios.post(`/trainingSessions/${payload.sessionID}/participants`, {
        access: payload.access,
        athleteID: payload.athleteID
      });

      return true;

    } catch (err) {
      let message = "Ops, qualcosa è andato storto nell'aggiungere questo atleta";
      if (err?.response?.data?.message === 'training session is not associated to an open day') {
        message = "Questo allenamento non è associato a nessun open day. Non puoi aggiungere nuovi partecipanti";
      }
      if (err?.response?.data?.message === 'open day event is not public') {
        message = "Questo allenamento è associato a un open day non pubblico. Pubblica l'open day per aggiungere nuovi partecipanti";
      }
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: message,
        show: true,
        timeout: 3000
      }, { root: true });
      return false;
    }
    finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async removeParticipantFromSession({ commit }, payload) {
    commit('SET_LOADING', true, { root: true });

    try {
      await this.$axios.delete(`/trainingSessions/${payload.trainingSession}/participants/${payload.participantID}`);

      return true;

    } catch (err) {
      console.error(err);

      return false;

    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async updatePresence({ commit }, { sessionId, participantId, isPresent }) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.patch(`/trainingSessions/${sessionId}/participants/${participantId}`, { isPresent });
      return res.data;

    } catch (err) {
      console.error(err);

      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async updateParticipantNote({ commit }, { session, participant, note }) {
    commit('SET_LOADING', true, { root: true });

    try {
      await this.$axios.patch(`/trainingSessions/${session}/participants/${participant}`, { notes: note });

      return true;
    } catch (err) {
      console.error(err);

      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Ops, qualcosa è andata male nel salvataggio della nota"
      }, { root: true });

      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async removeAthleteFromSession({ commit }, { sessionId, participantId }) {
    commit('SET_LOADING', true, { root: true });

    try {
      await this.$axios.delete(`/trainingSessions/${sessionId}/participants/${participantId}`);
      return true;

    } catch (err) {
      console.error(err);

      return false;

    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async deleteSession({ commit, dispatch }, id) {
    commit('SET_LOADING', true, { root: true });

    try {
      await this.$axios.delete(`/trainingSessions/${id}`);
      await dispatch('getSessions');

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },

  async unsubscriptionRefound({ commit }, { participantId, sessionId }) {
    commit('SET_LOADING', true, { root: true });

    try {
      const res = await this.$axios.get(`/trainingSessions/${sessionId}/participants/${participantId}/unsubscriptionRefund`);

      return res.data;
    } catch (err) {
      console.log(err);
      return null;
    } finally {
      commit('SET_LOADING', false, { root: true });
    }
  },


};
