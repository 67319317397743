export const state = () => ({
    locations: [],
    location: {},
});

export const getters = {
    locationHtmlDescription: state => {
        return state.location?.description?.replace(/\n/g, "<br />");
    },
    locationAccommodationHtmlDescription: state => {
        return state.location?.accommodation?.replace(/\n/g, "<br />");
    },
    locationParkingHtmlDescription: state => {
        return state.location?.parking?.replace(/\n/g, "<br />");
    },
    locationFoodHtmlDescription: state => {
        return state.location?.food?.replace(/\n/g, "<br />");
    },
}

export const mutations = {
    SET_LOCATIONS(state, payload) {
        state.locations = payload;
    },
    SET_LOCATION(state, payload) {
        state.location = payload;
    },
};

export const actions = {
    // LOCATION
    async createLocation({ commit, state, rootState }, data) {
        try {
            const res =await this.$axios.post('/locations', data);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo salvato la struttura con successo",
                show: true,
                timeout: 3000,
            }, { root: true });
            return res;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto",
                show: true,
                timeout: 3000
            }, { root: true });
            console.log("Something went wrong with the location", err)
            return null;
        }
    },
    async fetchLocations({ commit, rootState }) {
        try {
            const res = await this.$axios.get('/locations');
            return res.data;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto nel recuperare le tue strutture",
                show: true,
                timeout: 3000,
            }, { root: true });
            console.warn("Something went wrong with locations", err)
        }
    },
    async fetchLocationById({ commit, rootState }, id) {
        try {
            const res = await this.$axios.get('/locations/' + id);
            return res.data
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto nel recuperare questa struttura",
                show: true,
                timeout: 3000,
            }, { root: true });
            console.warn("Something went wrong with the location", err)
        }

        commit('SET_LOADING', false, { root: true });
    },
    async patchLocation ({ commit, state, rootState }, data) {
        try {
            const res = await this.$axios.patch('/locations/' + data.id, data);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo salvato le nuove modifiche con successo",
                show: true,
                timeout: 3000,
            }, { root: true });
            return res;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto",
                show: true,
                timeout: 3000
            }, { root: true });
            console.log("Something went wrong with the location patch", err)
            return null;
        }
    },
    async deleteLocation({ commit, rootState }, id) {
        try {
            await this.$axios.delete('/locations/' + id);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Struttura cancellata con successo",
                show: true,
                timeout: 3000,
            }, { root: true });
            return true;
        } catch (error) {
            let errorMessage = "";
            switch (error.response.status) {
                case 500:
                    errorMessage = "Problema sconosciuto del server, contatta un amministratore"
                    break;
                case 404:
                    errorMessage = "Non abbiamo trovato la risorsa da eliminare, contatta un amministratore"
                    break;
                case 400:
                    errorMessage = "Non puoi eliminare questa location perché è associata a un evento"
            }

            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: errorMessage,
                show: true,
                timeout: 4000
            }, {root: true});
            console.warn("There was an errror during PASSWORD RECOVER", error);

            return false;
        }
    },
    async fetchLocationsWithPendingReservations({ commit, rootState }) {
      try {
        const res = await this.$axios.get('/reservations/locations/pending-reservations');
        return res.data.locations || [];
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel recuperare le tue strutture",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with locations", err);
        return [];
      }
    },

    // LOCATION RESERVATION DATA
    async fetchLocationReservationDataById({ commit, rootState }, id) {
      try {
        const res = await this.$axios.get(`/reservations/locations/${id}`);
        return res.data
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel recuperare questa struttura",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with the location reservation data", err)
      }

      commit('SET_LOADING', false, { root: true });
    },
    async patchLocationReservation ({ commit, state, rootState }, { id, data }) {
      try {
        const res = await this.$axios.put(`/reservations/locations/${id}`, data);
        return res;
      } catch (err) {
        console.log("Something went wrong with the location patch", err)
        return null;
      }
    },

    async patchLocationReservationLimits ({ commit, state, rootState }, { id, data }) {
      try {
        const res = await this.$axios.put(`/reservations/locations/${id}/limits`, data);
        commit("user/SHOW_MESSAGE", {
          color: "success",
          message: "Abbiamo salvato le nuove modifiche con successo",
          show: true,
          timeout: 3000,
        }, { root: true });
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto",
          show: true,
          timeout: 3000
        }, { root: true });
        console.log("Something went wrong with the location limits patch", err);
        return null;
      }
    },

    // LOCATION MEDIA
    async addLocationMedia({ commit }, payload) {
        try {
          // Created the form data
          let formData = new FormData();
          payload.images.forEach(image => {
            formData.append('image', image);
          })

          // Sends the resources to the API
          const resourcesApi = await this.$axios.post("/images", formData);

          try {
            // Creates an array of resources ID based on the resource response
            let ids = [];
            resourcesApi.data.forEach(el => {
              ids.push(el.id);
            })
            const body = { media: ids }

            // Sends the array of the IDs to create the gallery
            const assignResources = await this.$axios.post(`/reservations/locations/${payload.locationId}/media`, body);
            return assignResources;
          } catch (err) {
            commit("user/SHOW_MESSAGE", {
              color: "error",
              message: "Qualcosa è andato storto nel salvataggio della gallery",
              show: true,
              timeout: 3000,
            }, { root: true });
            return null;
          }
        } catch (error) {
          console.log(error)
          let errorMessage = "";
          switch (error?.response?.status) {
            case 500:
              errorMessage = "Problema sconosciuto del server, contatta un amministratore"
              break;
            case 404:
              errorMessage = "Non abbiamo trovato la tua utenza nei nostri database"
              break;
            case 401:
              errorMessage = "Non sei autorizzato a fare questa operazione"
              break;
            default:
              errorMessage = "Ops, qualcosa è andato storto"
          }

          commit("user/SHOW_MESSAGE", {
            color: "error",
            message: errorMessage,
            show: true,
            timeout: 4000
          });
          console.warn("There was an error during IMAGE UPLOAD", error);
          return null;
        }
      },
    async deleteLocationMedia({ commit }, payload) {
      try {
        const res = await this.$axios.delete(`/reservations/locations/${payload.locationId}/media/${payload.mediaId}`);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Ops, qualcosa è andato storto",
          show: true,
          timeout: 3000
        }, { root: true });
        console.log("Something went wrong with the media file deletion", err)
        return null;
      }
    },

    // LOCATION OPENING SLOTS
    async fetchLocationOpeningSlots({ commit }, id) {
      try {
        const res = await this.$axios.get(`/reservations/locations/${id}/opening-slots`);
        return res.data.openingSlots || [];
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel recuperare le aperture settimanali",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location opening slots", err)
      }
    },
    async createLocationOpeningSlot({ commit }, { locationId, openingSlot: { dayOfWeek, startAt, endAt } }) {
      try {
        const res = await this.$axios.post(`/reservations/locations/${locationId}/opening-slots`, {
          dayOfWeek,
          startAt,
          endAt,
        });
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel creare un'apertura settimanali",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location weekly opening slot creation", err)
      }
    },
    async updateLocationOpeningSlot({ commit }, { locationId, openingSlotId, startAt, endAt }) {
      try {
        const res = await this.$axios.put(`/reservations/locations/${locationId}/opening-slots/${openingSlotId}`, {
          startAt,
          endAt,
        });
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel modificare un'apertura settimanale",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location opening slot update", err)
      }
    },
    async deleteLocationOpeningSlot({ commit }, { locationId, openingSlotId }) {
      try {
        const res = await this.$axios.delete(`/reservations/locations/${locationId}/opening-slots/${openingSlotId}`);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel cancellare un'apertura settimanale",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location opening slot deletion", err)
      }
    },

    // LOCATION CLOSING PERIODS
    async fetchLocationClosingPeriods({ commit }, id) {
      try {
        const res = await this.$axios.get(`/reservations/locations/${id}/closing-periods`);
        return res.data.closingPeriods || [];
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel recuperare i periodi di chiusura",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location closing periods", err)
      }
    },
    async createLocationClosingPeriod({ commit }, { locationId, day }) {
      try {
        const res = await this.$axios.post(`/reservations/locations/${locationId}/closing-periods`, { day });
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel creare un periodo di chiusura",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location weekly opening slot creation", err)
      }
    },
    async deleteLocationClosingPeriod({ commit }, { locationId, day }) {
      try {
        const res = await this.$axios.delete(`/reservations/locations/${locationId}/closing-periods/${day}`);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel cancellare un giorno di chiusura",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location opening slot deletion", err)
      }
    },

    // LOCATION PRICE LISTS
    async fetchLocationPriceLists({ commit }, id) {
    try {
      const res = await this.$axios.get(`/reservations/locations/${id}/price-lists`);
      return res.data.priceLists || [];
    } catch (err) {
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: "Qualcosa è andato storto nel recuperare i listini",
        show: true,
        timeout: 3000,
      }, { root: true });
      console.warn("Something went wrong with location price lists", err)
    }
  },
    async createLocationPriceList({ commit }, { locationId, priceList }) {
      try {
        const res = await this.$axios.post(`/reservations/locations/${locationId}/price-lists`, priceList);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel creare un listino",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location price list creation", err)
      }
    },
    async updateLocationPriceList({ commit }, { locationId, priceListId, priceList }) {
      try {
        const res = await this.$axios.put(`/reservations/locations/${locationId}/price-lists/${priceListId}`, priceList);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nell'aggiornare un listino",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location price list update", err)
      }
    },
    async deleteLocationPriceList({ commit }, { locationId, priceListId }) {
      try {
        const res = await this.$axios.delete(`/reservations/locations/${locationId}/price-lists/${priceListId}`);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel cancellare un listino",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location price list deletion", err)
      }
    },

    // LOCATION FIELDS
    async fetchLocationFields({ commit }, id) {
      try {
        const res = await this.$axios.get(`/reservations/locations/${id}/fields`);
        return res.data.fields || [];
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel recuperare i campi",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location fields", err)
      }
    },
    async createLocationField({ commit }, { locationId, field }) {
      try {
        const res = await this.$axios.post(`/reservations/locations/${locationId}/fields`, field);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel creare un campo",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location field creation", err)
      }
    },
    async updateLocationField({ commit }, { locationId, fieldId, field }) {
      try {
        const res = await this.$axios.put(`/reservations/locations/${locationId}/fields/${fieldId}`, field);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nell'aggiornare un campo",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location field update", err)
      }
    },
    async deleteLocationField({ commit }, { locationId, fieldId }) {
      try {
        const res = await this.$axios.delete(`/reservations/locations/${locationId}/fields/${fieldId}`);
        return res;
      } catch (err) {
        commit("user/SHOW_MESSAGE", {
          color: "error",
          message: "Qualcosa è andato storto nel cancellare un campo",
          show: true,
          timeout: 3000,
        }, { root: true });
        console.warn("Something went wrong with location field deletion", err)
      }
    },
};
