import flagsmith from 'flagsmith';

export const state = () => ({
  flags: {},
  initialized: false,
});

export const mutations = {
  SET_FLAGS(state, flags) {
    console.log('SET_FLAGS', flags);
    state.flags = structuredClone(flags);
  },
  SET_INITIALIZED(state, initialized) {
    console.log('SET_INITIALIZED', initialized);
    state.initialized = initialized;
  },
};

export const actions = {
  async init({ commit }, { baseUrl, environmentId }) {
    try {
      await flagsmith.init({
        api: `${baseUrl}/api/v1/`,
        environmentID: environmentId,
        cacheFlags: false,
        preventFetch: true,
        realtime: false,
        onChange: () => commit("SET_FLAGS", flagsmith.getAllFlags()),
      });
      commit('SET_FLAGS', flagsmith.getAllFlags());
      commit('SET_INITIALIZED', true);
    } catch (error) {
      console.error(error);
    }
  }
};

export const getters = {
  getFlag: (state) => (key) => state.flags[key]?.enabled || false,
  getFlagValue: (state) => (key) => state.flags[key]?.value || false,
  allFlags: (state) => state.flags,
  isInitialized: (state) => state.isInitialized,
};
