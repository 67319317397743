export default async ({ store, nuxtState, app }) => {
  await store.dispatch('flags/init', {
    baseUrl: window.dink?.flagsmithBaseUrl || nuxtState.config.flagsmith.baseURL,
    environmentId: window.dink?.flagsmithEnvironment || nuxtState.config.flagsmith.environmentId,
  });

  // Navigate to maintenance page if platform is in maintenance
  store.watch(
    (state) => state.flags.flags['maintenance']?.enabled,
    (isEnabled) => {
      if (isEnabled) {
        app.router.push('/maintenance');
      }
    }
  );
};
