import Vue from 'vue';

export default ({app}, inject) => {
  const vuetify = app.vuetify;

  if (!vuetify) {
    console.warn('Module modal needs vuetify instance.')
  }

  const contextContainer = Vue.extend(Object.assign(app));

  /**
   *
   * @param {*} component - A component to be instanciated as a modal
   * @param {*} options - An object containing props and events to be passed to the created component
   * @returns
   */
  function createModal (component, options = {}) {
    console.debug('Creating modal for', getComponentName(component), options);
    const ModalContainer = contextContainer.extend(component)
    const container = document.querySelector('[data-app=true]') || document.body;

    const closeModal = (type, resolve, instance, data = null) => {
      instance.showModal = false;
      resolve({type, data});
      setTimeout(() => instance.$destroy());
    };

    const events = Object.entries(options.events ?? {});
    const cmpInstance = new ModalContainer({
      propsData: Object.assign(
        {
          showModal: true,
        },
        options.props
      ),
      destroyed: () => container.removeChild(cmpInstance.$el),
    });

    let promiseResolve = () => null;
    const resultPromise = new Promise(resolve => {
      promiseResolve = resolve;

      const closeEvents = ['closeModal', 'close', 'save'];
      if(!options.skipAutoEvents) {
        cmpInstance.$once("closeModal", e => closeModal('close', resolve, cmpInstance, e));
        cmpInstance.$once("close", e => closeModal('close', resolve, cmpInstance, e));
        cmpInstance.$once("save", e => closeModal('save', resolve, cmpInstance, e));
      }
      for(const [name, handler] of events) {
        // if(options.skipAutoEvents && closeEvents.includes(name))
        cmpInstance.$on(name, handler);
      }

      container.appendChild(cmpInstance.$mount().$el);
    });

    return {
      close: () => closeModal('close', promiseResolve, cmpInstance, null),
      result: resultPromise,
    };
  }

  inject("showModal", (component, options) => createModal(component, options));
};

function getComponentName(component) {
  if(component.name) return component.name;
  if(component.__file) return component.__file.split('/').at(-1);
  console.warn('Given component does not have a "name" attribute in its export');

  return component.toString();
}
