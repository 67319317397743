const weekdaysMap = {
  mon: "monday",
  tue: "tuesday",
  wed: "wednesday",
  thu: "thursday",
  fri: "friday",
  sat: "saturday",
  sun: "sunday",
};

export default ({ app }, inject) => {
  // Deep copy an object
  inject("copy", obj => structuredClone(obj));
  inject("cp", obj => structuredClone(obj));

  // Return the full path for the given image
  inject("image", function(fileData = {}, type) {
    return `${fileData.baseURL || fileData.baseUrl}${fileData[type]}`;
  });

  inject("weekday", function(shortDay = '') {
    return weekdaysMap[shortDay.toLowerCase()]
  });

  inject("price", function(price = null, currency = null) {
    let formattedPrice = (Number(price) / 100).toFixed(2);
    return currency ? `${formattedPrice} ${currency}` : formattedPrice;
  });

  inject("formatRangedPrice", function(slot, currency = null) {
    if(slot.type === "by_slot") {
      return this.$price(slot.price, currency);
    }
    const ranges = [...slot.priceRanges].sort((a, b) => b.price - a.price);
    return `${this.$price(ranges[ranges.length - 1].price)}${ranges.length > 1 ?` ÷ ${this.$price(ranges[0].price)}` : ''} ${currency}`; // 12.00 ÷ 15.00 €
    // return `${this.$price(ranges[ranges.length - 1].price, currency)} ÷ ${this.$price(ranges[0].price, currency)}`; // 12.00 € ÷ 15.00 €
    // return ranges.map(r => this.$price(r.price, currency)).join("/"); // 15.00 €/13.00 €/12.00 €
    // return `${ranges.map(r => this.$price(r.price)).join("/")} ${currency}`; // 15.00/13.00/12.00 €
  });

  inject("parseLineBreaks", function(string = '') {
    return string.replace(/\n/g, "<br />");
  })
};
